var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"100vh mt-15"},[_c('v-container',{attrs:{"bg":"","fill-height":"","grid-list-md":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('v-img',{staticClass:"mb-10 mx-auto",attrs:{"width":"185px","height":"60px","src":require("@/assets/logo/facenet.png")}}),_c('v-card',{staticClass:"wd-486 ma-auto pa-4",attrs:{"rounded":"md","flat":""}},[_c('v-card-title',{staticClass:"font-24 fw-600 greyColor--text mb-2"},[_vm._v("Set First Time Password")]),_c('v-card-subtitle',{staticClass:"font-14 fw-400 secondary--text mb-2"},[_vm._v("Enter new password for your account.")]),_c('v-form',{ref:"setFirstTimePassword",on:{"submit":function($event){$event.preventDefault();return _vm.setPassword.apply(null, arguments)}}},[_c('v-card-text',_vm._l((Object.keys(_vm.formData)),function(item,index){return _c('div',{key:index},[_c('label',{staticClass:"font-13 greyColor--text fw-500"},[_vm._v(_vm._s(item == "password" ? "New Password" : "Confirm Password"))]),_c('v-text-field',{staticClass:"my-3 font-13 fw-500",attrs:{"height":"44","dense":"","outlined":"","color":"primary","label":item == 'password_confirmation'
                      ? 'Confirm Password'
                
                      : 'Password',"type":item == 'password'
                      ? _vm.showPassword
                        ? 'text'
                        : 'password'
                      : item == 'password_confirmation'
                      ? _vm.showConfirmPassword
                        ? 'text'
                        : 'password'
                   
                      :'',"rules":item === 'password'
                      ? _vm.passwordRules
                      : item == 'password_confirmation'
                      ? _vm.confirmPasswordRules
                      : [],"append-icon":item == 'password'
                      ? _vm.showPassword
                        ? 'mdi-eye primary--text'
                        : 'mdi-eye-off primary--text'
                      : item == 'password_confirmation'
                      ? _vm.showConfirmPassword
                        ? 'mdi-eye primary--text'
                        : 'mdi-eye-off primary--text'
                      : ''},on:{"click:append":() =>
                      item == 'password'
                        ? (_vm.showPassword = !_vm.showPassword)
                        : item == 'password_confirmation'
                        ? (_vm.showConfirmPassword = !_vm.showConfirmPassword)
                        : ''},model:{value:(_vm.formData[item]),callback:function ($$v) {_vm.$set(_vm.formData, item, $$v)},expression:"formData[item]"}})],1)}),0),_c('v-card-actions',{staticClass:"pa-3"},[_c('v-btn',{staticClass:"textColor--text ml-2 letter-space text-transform-none",attrs:{"color":"primary","height":"50px","block":"","depressed":"","type":"submit","loading":_vm.isLoading}},[_vm._v("Set First Time Password")])],1)],1),_c('back-to-login')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }