<template>
  <div class="100vh mt-15">
    <v-container bg fill-height grid-list-md text-xs-center>
      <v-layout row wrap align-center>
        <v-flex>
          <v-img
            class="mb-10 mx-auto"
            width="185px"
            height="60px"
            src="@/assets/logo/facenet.png"
          ></v-img>
          <v-card rounded="md" flat class="wd-486 ma-auto pa-4">
            <v-card-title class="font-24 fw-600 greyColor--text mb-2"
              >Set First Time Password</v-card-title
            >
            <v-card-subtitle class="font-14 fw-400 secondary--text mb-2"
              >Enter new password for your account.</v-card-subtitle
            >
            <v-form ref="setFirstTimePassword" @submit.prevent="setPassword">
              <v-card-text>
                <div
                  v-for="(item, index) of Object.keys(formData)"
                  :key="index"
                >
                  <label class="font-13 greyColor--text fw-500">{{
                   item == "password" ? "New Password"   : "Confirm Password"
                  }}</label>
                  <v-text-field
                    class="my-3 font-13 fw-500"
                    height="44"
                    dense
                    outlined
                    color="primary"
                    :label="
                      item == 'password_confirmation'
                        ? 'Confirm Password'
                  
                        : 'Password'
                    "
                    :type="
                      item == 'password'
                        ? showPassword
                          ? 'text'
                          : 'password'
                        : item == 'password_confirmation'
                        ? showConfirmPassword
                          ? 'text'
                          : 'password'
                     
                        :''
                    "
                    :rules="
                 
                      item === 'password'
                        ? passwordRules
                        : item == 'password_confirmation'
                        ? confirmPasswordRules
                        : []
                    "
                    :append-icon="
                      item == 'password'
                        ? showPassword
                          ? 'mdi-eye primary--text'
                          : 'mdi-eye-off primary--text'
                        : item == 'password_confirmation'
                        ? showConfirmPassword
                          ? 'mdi-eye primary--text'
                          : 'mdi-eye-off primary--text'
                        : ''
                    "
                    @click:append="
                      () =>
                        item == 'password'
                          ? (showPassword = !showPassword)
                          : item == 'password_confirmation'
                          ? (showConfirmPassword = !showConfirmPassword)
                          : ''
                    "
                    v-model="formData[item]"
                  />
                </div>
              </v-card-text>
              <v-card-actions class="pa-3">
                <v-btn
                  color="primary"
                  height="50px"
                  block
                  depressed
                  type="submit"
                  class="textColor--text ml-2 letter-space text-transform-none"
                  :loading="isLoading"
                  >Set First Time Password</v-btn
                >
              </v-card-actions>
            </v-form>
            <back-to-login />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/Api";
import BackToLogin from "@/components/common/buttons/BackToLogin.vue";
import {
  showErrorMessage,
  showNotificationBar,
  showSimpleErrorMessage,
} from "@/utils/showError";

export default {
  components: { BackToLogin },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
    
      isLoading: false,
      formData: {
     
        password: "",
        password_confirmation: "",
        
      },
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Password must have at least 8 characters",
        (v) => (v && /[A-Z]/.test(v)) || "Password must contain an uppercase letter",
        (v) => (v && /[a-z]/.test(v)) || "Password must contain a lowercase letter",
        (v) => (v && /\d/.test(v)) || "Password must contain a number",
        (v) => (v && /[!@#$%^&*]/.test(v)) || "Password must contain a special character (!@#$%^&*)",
      ],
      confirmPasswordRules: [
        (v) => !!v || "Type confirm password",
        (v) =>
          v === this.formData.password ||
          "The password confirmation does not match.",
      ],
     
    };
  },
  methods: {
    /**
     * Method to set user's first time login password
     */
    setPassword() {
      if (!this.$refs.setFirstTimePassword.validate()) {
        return showSimpleErrorMessage("Please fill required fields correctly!");
      }
      this.isLoading = true;
      ApiService.PostRequest(
        `/merchant/set-account-password?token=${this.$route.params.token}`,
        {
          email: this.$route.params.email,
          password: this.formData.password,
          password_confirmation: this.formData.password_confirmation,
        
        }
      )
        .then((response) => {
          this.$router.push("/");
          this.isLoading = false;
          showNotificationBar("success", response.data.message);
        })
        .catch((error) => {
          this.isLoading = false;
          showErrorMessage(error);
        });
    },
  },
};
</script>

<style></style>
