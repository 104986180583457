<template>
  <div class="100vh mt-15">
    <v-container bg fill-height grid-list-md text-xs-center>
      <v-layout row wrap align-center>
        <v-flex>
          <v-img
            class="mb-16 mx-auto"
            width="185px"
            height="60px"
            src="@/assets/logo/facenet.png"
          ></v-img>
          <v-card rounded="md" flat class="wd-486 ma-auto pa-4">
            <v-card-title class="font-24 fw-600 greyColor--text mb-2"
              >Set Password</v-card-title
            >
            <v-card-subtitle class="font-14 fw-400 secondary--text mb-2"
              >Enter new password for your account.</v-card-subtitle
            >
            <v-form ref="resetPassword" @submit.prevent="resetPassword">
              <v-card-text>
                <label class="font-13 greyColor--text fw-500"
                  >New Password</label
                >
                <v-text-field
                  v-model="formData.password"
                  class="my-2 login-page font-13 fw-500"
                  placeholder="Password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  :type="show ? 'text' : 'password'"
                  outlined
                  height="48px"
                  dense
                  hide-details="auto"
                  :rules="passwordRules"
                  required
                ></v-text-field>
                <label class="font-13 greyColor--text fw-500"
                  >Confirm Password</label
                >
                <v-text-field
                  v-model="formData.password_confirmation"
                  placeholder="Confirm password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                  :type="show1 ? 'text' : 'password'"
                  outlined
                  height="44"
                  hide-details="auto"
                  dense
                  class="my-3 login-page font-13 fw-500"
                  :rules="confirmPasswordRules"
                  required
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="pa-3">
                <v-btn
                  color="primary"
                  height="50px"
                  block
                  depressed
                  type="submit"
                  class="textColor--text ml-2 letter-space text-transform-none"
                  :loading="isLoading"
                  >Reset Password</v-btn
                >
              </v-card-actions>
            </v-form>
            <back-to-login />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/Api";
import BackToLogin from "@/components/common/buttons/BackToLogin.vue";
import {
  showErrorMessage,
  showNotificationBar,
  showSimpleErrorMessage,
} from "@/utils/showError";
export default {
  components: { BackToLogin },
  data() {
    return {
      show: false,
      show1: false,
      isLoading: false,
      formData: {
        password: "",
        password_confirmation: "",
      },
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 8) || "Password must have at least 8 characters",
        (v) =>
          (v && /[A-Z]/.test(v)) || "Password must contain an uppercase letter",
        (v) =>
          (v && /[a-z]/.test(v)) || "Password must contain a lowercase letter",
        (v) => (v && /\d/.test(v)) || "Password must contain a number",
        (v) =>
          (v && /[!@#$%^&*]/.test(v)) ||
          "Password must contain a special character (!@#$%^&*)",
      ],
      confirmPasswordRules: [
        (v) => !!v || "Type confirm password",
        (v) =>
          v === this.formData.password ||
          "The password confirmation does not match.",
      ],
    };
  },
  methods: {
    /**
     * Method to reset password, in case user forgets his/her password
     */
    resetPassword() {
      if (!this.$refs.resetPassword.validate()) {
        return showSimpleErrorMessage("Please fill required fields correctly!");
      }
      this.isLoading = true;
      ApiService.PostRequest(
        `/merchant/reset-password?token=${this.$route.params.token}`,
        this.formData
      )
        .then((response) => {
          showNotificationBar("success", response.data.message);
          this.$router.push("/");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          showErrorMessage(error);
        });
    },
  },
};
</script>

<style></style>
